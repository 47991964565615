// Dependencies.
import styled from 'styled-components'

import { fontWeights } from '../utils'

// H3.
export const H3 = styled.h3`
  color: #2b2b2b;
  font-size: 26px;
  font-weight: ${fontWeights.semiBold};
  letter-spacing: -0.6px;
  line-height: 110%;
  margin: 0;
  text-align: center;
`
