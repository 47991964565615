// Dependencies.
import styled from 'styled-components'

import { fontWeights } from '../utils'

// H4.
export const H4 = styled.h4`
  font-size: 20px;
  font-style: ${fontWeights.normal};
  font-weight: 600;
  letter-spacing: -0.6px;
  line-height: 130%;
  margin: 0;
`
