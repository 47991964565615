// Dependencies.
import styled from 'styled-components'

import { fontWeights } from '../utils'

// H6.
export const H6 = styled.h6`
  color: #171515;
  font-size: 13px;
  font-weight: ${fontWeights.medium};
  letter-spacing: 0.5px;
  line-height: 110%;
  margin: 0;
  text-transform: uppercase;
`
