// Dependencies.
import styled from 'styled-components'

import { colors, fontWeights } from '../utils'

// H5.
export const H5 = styled.h5`
  color: ${colors.offBlack};
  font-weight: ${fontWeights.medium};
  font-size: 16px;
  letter-spacing: -0.6px;
  line-height: 140%;
  margin: 0;

  em {
    color: ${colors.purple};
    font-size: inherit;
    font-style: inherit;
  }
`
