// Libs
import React from 'react'
import styled, { css } from 'styled-components'

// Comps
import { colors, alphaHex } from '../../utils'
import { Form } from '../../utils/validationRules'

export const errorSetStyles = css`
  p {
    color: ${colors.red};
    font-size: 14px;
    margin: 0px;
    margin-top: 8px;
  }
`

export const ErrorSetContainer = styled.div<Props>`
  ${errorSetStyles}
`

interface Props {
  errors: string[]
  className?: string
}

export const ErrorSet: React.FC<Props> = (props) => {
  // Props
  const { className, errors } = props
  // ..
  return (
    <ErrorSetContainer className={`ErrorSet ${className}`} errors={errors}>
      {Form.mapErrors(errors || [])}
    </ErrorSetContainer>
  )
}
