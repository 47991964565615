// Libs
import { useCallback, useEffect } from 'react'
import Cookies from 'js-cookie' // Client Side Only
import { useAsyncSetState } from 'use-async-setstate'

// Hook
function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useAsyncSetState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      const _item = item ? JSON.parse(item) : initialValue

      // only update storage if no previous value
      if (item === undefined || item == null) {
        window.localStorage.setItem(key, JSON.stringify(_item))
        Cookies.set(`ls-${key}`, JSON.stringify(_item))
      }
      return _item
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      setValue(initialValue)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value) => {
      try {
        // Save state
        setStoredValue(value)
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(value))
        Cookies.set(`ls-${key}`, JSON.stringify(value))
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error)
      }
    },
    [key, setStoredValue],
  )

  const onChange = useCallback(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      setStoredValue(item ? JSON.parse(item) : initialValue)
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      setStoredValue(initialValue)
    }
  }, [initialValue, key, setStoredValue])

  useEffect(() => {
    // console.log('MT')
    window.addEventListener('storage', onChange)
    return () => {
      window.removeEventListener('storage', onChange)
    }
  }, [initialValue, key, onChange, setStoredValue])

  return [storedValue, setValue]
}

// Bug fox for SSR
export const useSsrLocalStorage = (key, initial) => {
  return typeof window === 'undefined'
    ? [initial, (value) => undefined]
    : // disable rules of hooks. It'll be better to rewrite this part, but this logic is well tested, so, for now, rule just disabled
      /* eslint-disable-next-line react-hooks/rules-of-hooks */
      useLocalStorage(key, initial)
}
