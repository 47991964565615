// Dependencies.
import styled from 'styled-components'

import { fontWeights } from '../utils'

// H1.
export const H1 = styled.h1`
  color: #2b2b2b;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: ${fontWeights.bold};
  font-size: 54px;
  line-height: 110%;
  letter-spacing: -2px;
  margin: 0;
  text-align: center;
`
