import styled from 'styled-components'
import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { CTA } from './CTA'
import { useXendit } from '../hooks/useXendit'
import { ErrorSet as ErrorSetBase } from './Forms/ErrorSet'
import { isNonEmptyString } from '../utils/isNonEmptyString'
import { EventHookInterface } from '../types'
import { useSsrLocalStorage } from '../hooks/useLocalStorage'

const ErrorSet = styled(ErrorSetBase)`
  display: flex;
  justify-content: center;
`

interface Props {
  buttonText: string
  groupId: number
  origin: string
  className?: string
  redirect?: string
  onSuccess?: () => void
  afterSuccess?: () => void
  onError?: () => void
  eventHook?: EventHookInterface
}

const XendidOnBoardingButton: React.FC<Props> = (props) => {
  const { connect, queryIsSetupInProgress } = useXendit()
  const [isError, setIsError] = useState(false)
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const {
    buttonText,
    groupId,
    className,
    onSuccess,
    afterSuccess,
    redirect,
    eventHook,
  } = props

  const handleSuccess = useCallback(async () => {
    if (typeof onSuccess === 'function') {
      onSuccess()
    } else {
      if (eventHook && eventHook.value) {
        eventHook.saveDraft()
      }
      router.push(`/xendit/setup-in-progress?group_id=${groupId}`)
    }
    if (typeof afterSuccess === 'function') {
      afterSuccess()
    }
  }, [afterSuccess, groupId, onSuccess, router])

  const onClick = useCallback(() => {
    setLoading(true)
    queryIsSetupInProgress().then((isInProgress) => {
      if (isInProgress) {
        setLoading(false)
        handleSuccess()
      } else {
        connect(groupId, redirect).then(
          () => {
            setLoading(false)
            handleSuccess()
          },
          (err) => {
            const errorMessageFromResponse = err.response?.data?.message
            const errorMsg = isNonEmptyString(errorMessageFromResponse)
              ? errorMessageFromResponse
              : 'ERROR: unknown error'

            setError(errorMsg)
            setLoading(false)
            setIsError(true)
          },
        )
      }
    })
  }, [connect, groupId, handleSuccess, queryIsSetupInProgress, redirect])

  // ..
  return (
    <div>
      <CTA
        className={`StripeOnBoardingButton button authorizeButton ${className}`}
        disabled={isError || loading}
        onClick={onClick}>
        {buttonText}
      </CTA>

      {isError && <ErrorSet errors={[error]} className="SetupPaymentBtn" />}
    </div>
  )
}

export default XendidOnBoardingButton
