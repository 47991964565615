import { useTranslation } from 'next-i18next'

// Libs
import styled, { css } from 'styled-components'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import Image from 'next/image'

// Comps

import {
  colors,
  icons,
  forScreensGreaterThanMobileWidth,
  fontWeights,
  mobileScreen,
} from '../utils'
import { Modal } from './Modal'
import { CTA } from './CTA'
import { GroupModalType, EventHookInterface } from '../types'
import { useSsrLocalStorage } from '../hooks/useLocalStorage'
import { H4, H3, P } from './Headings'
import StripeOnBoardingButton from './StripeOnBoardingButton'
import XendidOnBoardingButton from './XendidOnBoardingButton'

const A = styled.a`
  color: ${colors.purple};

  &.active {
    text-decoration: underline;
  }

  &:hover {
    text-decoration: underline;
  }
`

const Close = styled.p`
  color: ${colors.purple};
  text-align: center;
  cursor: pointer;
  margin-bottom: 0 !important;
  font-weight: 500;
  font-size: 15px;
`

const Div = styled.div`
  position: relative;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  img {
    display: none;
  }
  width: 100%;

  .contentBox {
    padding: 40px;
    max-width: 90%;
    padding-top: 60px;
  }
  ${forScreensGreaterThanMobileWidth`
    width:100%;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;

  img {
      display: block;
      width: 50%;
  }
  `}

  .crossContainer {
    padding: 5px;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    img {
      width: 30px;
      height: 30px;
    }
  }

  .cancelButton {
    margin-left: 20px;

    &.greyButton {
      background-color: transparent;
      color: ${colors.grey};
      font-weight: ${fontWeights.medium};
    }
  }

  .modalHeader {
    width: 405px;
    height: 87px;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .modalContent {
    width: 405px;
    max-width: 100%;
    margin-bottom: 30px;

    img {
      max-height: 40px;
      max-width: fit-content;
      width: max-content;
    }

    ${forScreensGreaterThanMobileWidth`
       h3 {
        text-align: left;
    }
  `}

    .modalImage {
      display: none;
    }
    ${forScreensGreaterThanMobileWidth`
    .modalImage {
        height: 100%;
        width: 50%;
    }
  `}

    p {
      margin: 20px auto;
    }
  }

  .payments {
    margin-bottom: 0 !important;

    &__choose {
      margin-top: 16px;
    }

    &__block {
      margin-top: 22px;
    }
  }

  .buttons {
    margin: 40px auto 10px auto;
    text-align: center;
    display: flex;
    justify-content: space-between;

    & .StripeOnBoardingButton {
      margin-left: 20px;
    }

    button {
      width: 184px;
      ${mobileScreen(css`
        width: auto;
      `)}
    }
  }

  .zoomImage {
    width: 70px;
    height: 70px;
  }

  .zoomContainer {
    width: 202.41px;
    height: 106.27px;
    margin-top: 19.95px;
    margin-bottom: 6.78px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button {
    width: 263px;

    &.authorizeButton {
      margin-bottom: 15px;
    }
  }

  .listItem {
    display: flex;
    justify-content: space-between;
    &:not(:first-of-type) {
      margin-top: 10px;
    }
    svg {
      display: block;
      max-width: 24px;
      width: 100%;
      margin-right: 10px;
    }
    .listItemTitle {
      margin-right: 2px;
      color: ${colors.purple};
    }
    span {
      width: 100%;

      font-weight: 400;
      font-size: 16px;
      line-height: 1.3;
      letter-spacing: -0.6px;

      color: #2c2d35;
    }
    & .numberContainer {
      margin-right: 14px;

      & .number {
        background-color: ${colors.washedPurple};
        width: 22px;
        height: 22px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${colors.purple};
        font-size: 12px;
      }
    }
  }
`

const background: React.CSSProperties = {
  background: `${colors.white}`,
  maxWidth: '1000px',
  height: '550px',
  left: '50%',
  top: '50%',
  position: 'fixed',
  transform: 'translate(-50%, -50%)',
  overflow: 'hidden',
  borderRadius: '10px',
  boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.1)',
}

// Props.
interface Props {
  close: () => void
  modalType: GroupModalType
  groupId: number
  origin?: string
  eventHook?: EventHookInterface
}

interface ItemProps {
  title: string
  content: string
}

interface NumberProps {
  number?: string
  content: string
}

// Components
export const GroupModal: React.FC<Props> = (props) => {
  const { t } = useTranslation('common')
  // Props
  const { close, modalType, groupId, origin, eventHook } = props

  // Global State
  const router = useRouter()

  const afterSuccess = useCallback(() => {
    close()
  }, [close])

  //..
  return (
    <Modal
      style={background}
      close={close}
      retainPointerEvents={true}
      zIndex={9999}>
      <Div className="modalContainer">
        <img
          className=""
          src="/images/groupsmodal.png"
          alt={t(`picture of yoga`, `picture of yoga`)}
        />
        <div className="contentBox">
          <div className="crossContainer" onClick={close}>
            <img src={icons.close} className="crossImage" />
          </div>

          {/* Create Group */}
          {modalType === GroupModalType.NormalGroup && (
            <div className="modalContent">
              <H3>
                {t(
                  `GroupModal-564441687`,
                  `Setup a group account for added benefits`,
                )}
              </H3>
              <P>
                {t(
                  `GroupModal77294902`,
                  `A Klyk group account gives you a level up in keeping your community together. Give your group a home online, and the best part, it’s free!`,
                )}
              </P>
              <ListItem
                title={t(`Group pages`, `Group pages`)}
                content={t(
                  `GroupModal-203464798`,
                  `A sharable page that allows users to follow direct updates and participate in recurring activities`,
                )}
              />
              <ListItem
                title={t(`Recommendations`, `Recommendations`)}
                content={t(
                  `GroupModal-1251260381`,
                  `Members of your group will receive regular emails with upcoming events to stay in the loop on all group activities`,
                )}
              />
              <div className="buttons">
                <CTA
                  className="purple"
                  onClick={() => {
                    close()
                    router.push('/creategroup')
                  }}>
                  {t(`Lets get setup`, `Lets get setup`)}
                </CTA>
                <CTA className="cancelButton" onClick={close}>
                  {t(`Skip`, `Skip`)}
                </CTA>
              </div>
            </div>
          )}

          {/* Group Set Up*/}
          {modalType === GroupModalType.DropDownGroup && (
            <div className="modalContent">
              <H3>
                {t(
                  `GroupModal-1759866250`,
                  `Start earning from your events today in three easy steps!`,
                )}
              </H3>
              <P>
                {t(
                  `GroupModal-1439986272`,
                  `It's easy to begin earning money from your events. Powered by Stripe, you can charge a ticket price to attend your events and turn your passions into profit.`,
                )}
              </P>
              <NumberItem
                number="1"
                content={t(
                  `GroupModal-1268843507`,
                  `First, we'll get you set up with a group account to be eligible for paid events.`,
                )}
              />
              <NumberItem
                number="2"
                content={t(
                  `GroupModal1951863662`,
                  `You'll then need to link a Stripe account to securely manage your payments. If you don't have one, we'll take you through the simple set up process.`,
                )}
              />
              <NumberItem
                number="3"
                content={t(
                  `GroupModal-828060327`,
                  `That's it! You're ready to go. We'll bring you back here to finish creating your paid event.`,
                )}
              />
              <div className="buttons">
                <CTA
                  className="purple"
                  onClick={async () => {
                    // In the case we are working with an event on the event page remember to store it for when we come back
                    if (eventHook) {
                      eventHook.saveDraft()
                      window.scrollTo(0, 0)
                    }
                    // For any other case and if on create event page then go to set up group page
                    const success = await router.push(
                      '/creategroup?option=group',
                    )
                    if (success) close()
                  }}>
                  {t(`Lets get setup`, `Lets get setup`)}
                </CTA>
                <CTA className="cancelButton greyButton" onClick={close}>
                  {t(`Maybe later`, `Maybe later`)}
                </CTA>
              </div>
            </div>
          )}

          {/* Stripe Set Up - used on my events screen */}
          {modalType === GroupModalType.NormalStripe && (
            <div className="modalContent">
              <H3>
                {t(
                  `Setup Your Payment Account`,
                  `Setup Your Payment Account To Begin Accepting Payments For Your Events`,
                )}
              </H3>
              <div className="payments__choose">
                {t(`You can choose from`, `You can choose from`)}
                {` `}
                <strong>
                  {t(`two payment gateways:`, `two payment gateways:`)}
                </strong>
              </div>

              <div className="payments__block">
                <img src="/icons/StripeIcon.png" alt="stripe icon" />
                {t(
                  `Easy to integrate`,
                  `Easy to integrate and ready for scale. Accept 135+ Currencies.`,
                )}
                {` `}
                <A href="#">{t(`Learn more`, `Learn more`)}</A>
              </div>
              <div className="payments__block">
                <img src="/icons/XenditIcon.png" alt="xendit icon" />
                {t(
                  `A leading payment gateway`,
                  `A leading payment gateway for Indonesia, the Philippines and Southeast Asia.`,
                )}
                {` `}
                <A href="#">{t(`Learn more`, `Learn more`)}</A>
              </div>
              {console.log(groupId)}
              {console.log(origin)}
              <div className="buttons">
                <StripeOnBoardingButton
                  className="paymentProviderOnBoardingButton purple"
                  buttonText={t(`Set up Stripe`, `Set up Stripe`)}
                  groupId={groupId}
                  origin={origin}
                  redirect={`/events/create`}
                />

                <XendidOnBoardingButton
                  className="paymentProviderOnBoardingButton"
                  buttonText={t(`Set up Xendit`, `Set up Xendit`)}
                  groupId={groupId}
                  origin={origin}
                  redirect={`/events/create`}
                />
              </div>
            </div>
          )}

          {/* Used on Create Event to link to another page where stripe is set up */}
          {modalType === GroupModalType.DropDownStripe && (
            <div className="modalContent payments">
              <H3>
                {t(
                  `Setup Your Payment Account`,
                  `Setup Your Payment Account To Begin Accepting Payments For Your Events`,
                )}
              </H3>
              <div className="payments__choose">
                {t(`You can choose from`, `You can choose from`)}
                {` `}
                <strong>
                  {t(`two payment gateways:`, `two payment gateways:`)}
                </strong>
              </div>

              <div className="payments__block">
                <img src="/icons/StripeIcon.png" alt="stripe icon" />
                {t(
                  `Easy to integrate`,
                  `Easy to integrate and ready for scale. Accept 135+ Currencies.`,
                )}
                {` `}
                <A href="#">{t(`Learn more`, `Learn more`)}</A>
              </div>
              <div className="payments__block">
                <img src="/icons/XenditIcon.png" alt="xendit icon" />
                {t(
                  `A leading payment gateway`,
                  `A leading payment gateway for Indonesia, the Philippines and Southeast Asia.`,
                )}
                {` `}
                <A href="#">{t(`Learn more`, `Learn more`)}</A>
              </div>
              <div className="buttons">
                <StripeOnBoardingButton
                  className="paymentProviderOnBoardingButton purple"
                  buttonText={t(`Set up Stripe`, `Set up Stripe`)}
                  groupId={groupId}
                  origin={origin}
                  redirect={`/events/create`}
                />

                <XendidOnBoardingButton
                  className="paymentProviderOnBoardingButton"
                  buttonText={t(`Set up Xendit`, `Set up Xendit`)}
                  groupId={groupId}
                  origin={origin}
                  redirect={`/events/create`}
                  eventHook={eventHook}
                />
              </div>

              <Close onClick={close}>
                {t(
                  'No thanks, I will host free events',
                  'No thanks, I will host free events',
                )}
              </Close>
            </div>
          )}
          <div
            className="modalImage"
            style={{
              backgroundImage: `url(${'/images/aboutCTABackImage.png'})`,
            }}></div>
        </div>
      </Div>
    </Modal>
  )
}

const ListItem: React.FC<ItemProps> = (props) => {
  const { title, content } = props
  return (
    <div className="listItem">
      <svg
        width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22 11.08V12a10 10 0 11-5.93-9.14"
          stroke="#6D4BEA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 4L12 14.01l-3-3"
          stroke="#6D4BEA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div className="listItemText">
        <span className="listItemTitle">{title}:</span>
        <span className="listItemContent">{content}</span>
      </div>
    </div>
  )
}

const NumberItem: React.FC<NumberProps> = (props) => {
  const { number, content } = props
  return (
    <div className="listItem">
      {number && (
        <div className="numberContainer">
          <H4 className="number">{number}</H4>
        </div>
      )}
      <div className="listItemText">
        <span className="listItemContent">{content}</span>
      </div>
    </div>
  )
}
