// Libs
import styled from 'styled-components'

// Comps
import { CTA } from './CTA'
import { useSsrLocalStorage } from '../hooks/useLocalStorage'

// Defs
const Container = styled.div``

interface Props {
  buttonText: string
  groupId: number
  origin: string
  className?: string
  redirect?: string
}

// Start stripe onboarding
const StripeOnBoardingButton: React.FC<Props> = (props) => {
  // Props
  const { buttonText, groupId, origin, className, redirect } = props

  // Global State
  const [, setGoneToStripeSetUp] = useSsrLocalStorage(
    'started-payment-onboarding',
    false,
  )

  // Calc
  const url = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${getRedirectURI(
    redirect,
    origin,
  )}&client_id=${getClientId()}&state=${groupId}`

  // Funcs
  const onClick = async () => {
    await setGoneToStripeSetUp(true)
    window.location.href = url
  }

  // ..
  return (
    <Container>
      <CTA
        className={`StripeOnBoardingButton button authorizeButton ${className}`}
        onClick={onClick}>
        {buttonText}
      </CTA>
    </Container>
  )
}

export default StripeOnBoardingButton

// Get a client ID for stripe
const getClientId = (): string => {
  return process.env.NEXT_PUBLIC_STRIPE_CLIENT_ID
}

// Build a URI for stripe
const getRedirectURI = (redirect: string, origin: string): string => {
  let uri = ''
  if (redirect) {
    uri = `${origin}/oauth/stripe?redirect=${redirect}`
  } else {
    uri = `${origin}/oauth/stripe`
  }

  return encodeURIComponent(uri)
}
