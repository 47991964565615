// Dependencies
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

// Div.
const Div = styled.div`
  height: 100%;
  width: 100%;
  max-width: 100vw;
  overflow: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
  & > * {
    max-height: 100%;
  }
`

// Background
const Background = styled.div`
  background: #e5e6eb;
  cursor: pointer;
  left: 0;
  height: 100%;
  opacity: 0.6;
  pointer-events: auto;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`

// Props
export interface Props {
  backgroundStyles?: React.CSSProperties
  className?: string
  close?: () => void
  keepBodyLocked?: boolean
  lockBody?: boolean
  mobileOnly?: boolean
  retainPointerEvents?: boolean
  style?: React.CSSProperties
  zIndex?: number
}

// Modal
export const Modal: React.FC<Props> = (props) => {
  // Props
  const {
    backgroundStyles,
    children,
    className,
    close,
    keepBodyLocked,
    lockBody,
    mobileOnly,
    retainPointerEvents,
    style,
  } = props

  // State.
  const [container, setContainer] = useState<HTMLDivElement>(null)

  // On Change - Document.
  useEffect(() => {
    setContainer(document.createElement('div'))
  }, [])

  // On Change - Container.
  useEffect(() => {}, [container])

  // On Change - Target
  useEffect(() => {
    if (!container) return
    if (lockBody) document.body.style.overflow = 'hidden' // Lock Document Body
    document.body.appendChild(container)
    container.style.backgroundColor = 'rgba(229, 230, 235, 0.8)'
    container.style.height = '100vh'
    container.style.justifyContent = 'center'
    container.style.left = '0'
    container.style.top = '0'
    container.style.width = '100%'
    container.style.maxWidth = '100vw'
    container.style.position = 'fixed'
    container.style.overflow = 'auto'
    container.style.zIndex = (props.zIndex && `${props.zIndex}`) || '1'
    if (mobileOnly) container.classList.add('mobileOnly')
    return () => {
      container.remove()
      if (lockBody && !keepBodyLocked) document.body.style.overflow = 'visible' // Unlock Document Body
    }
  }, [container])

  // Container Ref.
  if (!container) return null

  // ..
  return ReactDOM.createPortal(
    <Div
      className={className}
      style={{
        pointerEvents: (retainPointerEvents && 'auto') || 'none',
        ...style,
      }}>
      <Background
        onClick={close}
        style={{ cursor: close ? 'pointer' : 'auto', ...backgroundStyles }}
      />
      {children}
    </Div>,
    container,
  )
}
