import { useCallback } from 'react'

import { MyGroup, User } from '../types'
import { usePaymentProvider } from './usePaymentProvider'

export function usePaymentGateway() {
  const { provider } = usePaymentProvider()

  const isSetupByGroup = useCallback((groupDetails?: Partial<MyGroup>) => {
    if (groupDetails === undefined) {
      return false
    }
    if (groupDetails.has_stripe_account || groupDetails.has_xendit_account) {
      return true
    }
    return false
  }, [])

  const isSetupByUser = useCallback(
    (userDetails: User) => {
      if (
        typeof userDetails === 'object' &&
        userDetails !== null &&
        userDetails &&
        userDetails?.managed_groups?.length > 0
      ) {
        return userDetails.managed_groups.some(isSetupByGroup)
      }
      return false
    },
    [isSetupByGroup],
  )

  return {
    isSetupByGroup,
    isSetupByUser,
  }
}
